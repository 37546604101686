import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/admin/account-creation": [3],
		"/admin/account-setup": [4],
		"/cookies": [5],
		"/email-verified": [6],
		"/guest/events/[id]": [7],
		"/guest/venue/[id]": [8],
		"/host": [9],
		"/host/account": [10],
		"/host/events": [11],
		"/host/events/[id]": [12],
		"/host/signup": [13],
		"/host/test-tts": [14],
		"/host/venue": [15],
		"/login": [16],
		"/password-reset": [17],
		"/privacy-policy": [18],
		"/signup": [19],
		"/start": [20],
		"/terms-of-use": [21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';