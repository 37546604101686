import "reflect-metadata";
import { Environment } from "$lib/environment/environment";
import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";
import { MIXPANEL_TOKEN } from "shared/src/statics";

const environment = new Environment();

import LogRocket from "logrocket";

posthog.init("phc_VknwfNxufZYAOkfogHH8aa2NL9Ll0PkUVZjMPXARjuM", {
  api_host: "https://app.posthog.com",
});

mixpanel.init(MIXPANEL_TOKEN, {
  debug: false,
  track_pageview: false,
  persistence: "localStorage",
});

if (environment.getEnvironment() === "production") {
  LogRocket.init("gzkjdy/moodstir", {
    shouldParseXHRBlob: true,
  });

  Sentry.init({
    environment: environment.getEnvironment(),
    dsn: "https://224b69a4f2a27b55d94c768fcc6887de@o4505926245679104.ingest.sentry.io/4505926248955904",
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      new Replay({
        blockAllMedia: false,
        maskAllText: false,
        networkDetailAllowUrls: ["corsproxy.io", "openai.com"],
      }),
    ],
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
